// enums for user
export const SHUK_AUTH_PORTAL_TOKEN = "sHuK_TokEn_AuTH_DasHBoaRd"
export const AUTH_USER_LOGIN_CONSTANT = "user/login"
export const AUTH_USER_VERIFY = "user/verify"
export const AUTH_USER_SENDCODE = "user/send"
export const AUTH_USER_REGISTER_CONSTANT = "user/register"

// enums for forgot & reset password
export const SHUK_FP_EMAIL_VERIFICATION = "ForgetPassword/EmailVerify"
export const SHUK_FP_AUTH_METHOD = "ForgetPassword/SelectMethod"
export const SHUK_FP_OTP_VERIFY = "ForgetPassword/OTPVerify"
export const SHUK_FP_RESET_PASSWORD = "ForgetPassword/ResetPass"
export const REGISTER_AUTHENTICATOR = "user/register-authentication"
