import PropTypes from "prop-types"
import React from "react"
// import { connect } from "react-redux"
import logoLightPng from "../../assets/images/Shukre_Ilahi_2.png"
// import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import { Link } from "react-router-dom"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <Link
            to="/"
            className="logo w-100 d-flex justify-content-center align-items-center mt-2"
          >
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="170" />
            </span>
          </Link>
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

// const mapStatetoProps = state => {
//   return {
//     layout: state.Layout,
//   }
// }
// export default connect(
//   mapStatetoProps,
//   {}
// )(withRouter(withTranslation()(Sidebar)))

export default Sidebar
