import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap"
import logoDark from "../../assets/images/Shukre_Ilahi_2.png"
import logoLight from "../../assets/images/logo.png"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import { userData, sendAuthenticatorCode } from "redux/slice/user/userSlice"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { IoMdArrowRoundBack } from "react-icons/io"
import { MdOutlinePassword } from "react-icons/md"
import { PiLinkBold } from "react-icons/pi"
import { IoCheckmarkCircleSharp } from "react-icons/io5"
import {
  Forgot_Pass_Otp_Verify,
  ForgotPasswordBlock,
  ForgotPasswordSendEmail,
  resetForgotState,
  VerifyEmail,
} from "redux/slice/user/forgotPasswordSlice"

const ForgetPasswordPage = props => {
  document.title = "Forgot Password | Shukre Ilahi"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { apiStatus, respFrom } = useSelector(userData)
  const { error, loading, response } = useSelector(ForgotPasswordBlock)
  const [stage, setStage] = useState(1)
  const [errorMsg, setErrorMsg] = useState("")
  const [authMechanism, setAuthMechanism] = useState("Link")
  const [resendEmail, setResendEmail] = useState("")

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      otpCode: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
          "Password must contain at least one letter, one number, and one special character",
        )
        .required("Please Enter Correct Email"),
    }),
    onSubmit: values => {
      if (stage === 1) {
        dispatch(resetForgotState())
        console.log("verifying email")
        setStage(2)
        // const data = { email: values.email}
        // dispatch(VerifyEmail(data))
        //   .then(result => {
        //     if (result?.payload?.response?.status === 200) {
        //       setStage(2)
        //     } else {
        //       console.log("Error verifying email.")
        //       setErrorMsg("Error verifying email.")
        //     }
        //   })
        //   .catch(err => {
        //     console.log("Login error", err)
        //     setErrorMsg("Error verifying email.")
        //   })
      } else if (stage === 2) {
        let data = {
          email: values.email,
        }
        dispatch(ForgotPasswordSendEmail(data))
        .then(result => {
          console.log("result from api email send", result)
          if (result?.payload?.response?.status ===200 ) {
            setStage(3)
          } else {
            console.log("Invalid authentication code.")
            setErrorMsg("Error Validating Code.")
          }
        })
        .catch(error => {
          console.log("error sending code", error)
          setErrorMsg("Error Validating Code.")
        })
      } else if (stage === 3) {
        navigate("/reset-password")
        let data = {
          email: values.email,
          otp: values.otpCode,
        }
        dispatch(Forgot_Pass_Otp_Verify(data))
          .then(result => {
            if (result?.payload?.response?.status === 200) {
              setTimeout(()=>{
                navigate("/login")
              }, 2000)
            } else {
              console.log("Invalid authentication code.")
              setErrorMsg("Error Validating Code.")
            }
          })
          .catch(error => {
            console.log("error sending code", error)
            setErrorMsg("Error Validating Code.")
          })
      }
    },
  })

  const handleResend = () => {
    dispatch(sendAuthenticatorCode(resendEmail))
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <img
                      src={logoDark}
                      alt=""
                      height="230"
                      className="auth-logo-dark"
                    />
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Forgot Password
                    </h4>

                    {/* {error ? (
                      <Alert color={"danger"}>{error}</Alert>
                    ) : null} */}

                    {(validation?.touched?.email && validation?.errors?.email) || (error && response) ? (
                      <Alert color={"danger"}>{validation?.errors?.email || response}</Alert>
                    ) : null}

                    {/* for back button && stage < 3 */}
                    {stage > 1 && stage < 3 && (
                      <button
                        type="button"
                        className="btn"
                        onClick={() => setStage(stage - 1)}
                      >
                        <IoMdArrowRoundBack size={20} /> Back
                      </button>
                    )}

                    {/* form stages */}
                    {stage === 1 && (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3">
                          <Label htmlFor="username">Email</Label>
                          <Input
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                          />
                        </div>

                        <div className="mb-3 mt-4 d-flex justify-content-end">
                          <button
                            className="btn btn-success w-md waves-effect waves-light"
                            type="submit"
                            disabled={loading || !validation.values.email}
                          >
                            {loading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                                style={{ width: "1.1rem", height: "1.1rem" }}
                              />
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </Form>
                    )}

                    {stage === 2 && (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <h5>Select Authorization Method</h5>
                        <Row className="mb-3">
                          <Col xs={12}>
                            {/* <div
                              className="card mb-3"
                              style={{
                                border: `${authMechanism == "OTP" ? "1px solid black" : "none"}`,
                              }}
                              onClick={() => setAuthMechanism("OTP")}
                            >
                              <div className="row g-0">
                                <div className="col-4 d-flex justify-content-center align-items-center">
                                  <MdOutlinePassword size={50} />
                                </div>
                                <div className="col-8">
                                  <div className="card-body">
                                    <h5 className="card-title">OTP</h5>
                                    <p className="card-text">
                                      The code will be send to your email
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div
                              className="card mb-3"
                              style={{
                                maxWidth: "540px",
                                border: `${authMechanism == "Link" ? "1px solid var(--theme-color-secondary)" : "1px solid transparent"}`,
                                boxShadow: `${authMechanism == "Link" ? "0 0 0 0.25rem var(--theme-color-secondary-shadow)" : "0 0 0 0.25rem transparent"}`
                              }}
                              onClick={() => setAuthMechanism("Link")}
                            >
                              <div className="row g-0">
                                <div className="col-4 d-flex justify-content-center align-items-center">
                                  <PiLinkBold size={50} />
                                </div>
                                <div className="col-8">
                                  <div className="card-body">
                                    <h5 className="card-title">Link</h5>
                                    <p className="card-text">
                                      The link will be send to your email
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3 mt-4">
                          <Col xs={12} className="text-end">
                            <button
                              className="btn btn-success w-md waves-effect waves-light"
                              type="submit"
                              disabled={!authMechanism}
                            >
                              Send
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    {stage === 3 && (
                      <div>
                        {authMechanism == "OTP" ? (
                          <Form
                            className="form-horizontal mt-4"
                            onSubmit={validation.handleSubmit}
                          >
                            <div className="mb-3">
                              <Label htmlFor="authcode">
                                Enter Verification Code
                              </Label>
                              <Input
                                name="authcode"
                                type="text"
                                placeholder="Enter verification code"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.authcode}
                              />
                              {validation.errors.authcode &&
                              validation.touched.authcode ? (
                                <FormFeedback>
                                  {validation.errors.authcode}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <Row className="mb-3 mt-4">
                              <Col xs={6}>
                                {authMechanism == "OTP" ? (
                                  <Link
                                    className="text-secondary"
                                    onClick={handleResend}
                                  >
                                    Resend code
                                  </Link>
                                ) : null}
                              </Col>
                              <Col xs={6} className="text-end">
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                  disabled={
                                    loading || !validation.values.authcode
                                  }
                                >
                                  {loading ? (
                                    <div
                                      className="spinner-border text-light"
                                      role="status"
                                      style={{
                                        width: "1.1rem",
                                        height: "1.1rem",
                                      }}
                                    />
                                  ) : (
                                    "Verify Code"
                                  )}
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          <div className="mt-4 d-flex flex-column justify-content-center align-items-center h-20">
                            <IoCheckmarkCircleSharp size={60} color="#008037" />
                            <p className="fs-5 text-secondary-si text-center">
                              Verification Link Sent to Your Email
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    {/* {stage === 3 && authMechanism == "OTP" && (
                      <Form
                        className="form-horizontal mt-4"
                        onSubmit={validation.handleSubmit}
                      >
                        <div className="mb-3">
                          <Label htmlFor="authcode">
                            Enter Verification Code
                          </Label>
                          <Input
                            name="authcode"
                            type="text"
                            placeholder="Enter verification code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.authcode}
                          />
                          {validation.errors.authcode &&
                          validation.touched.authcode ? (
                            <FormFeedback>
                              {validation.errors.authcode}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Row className="mb-3 mt-4">
                          <Col xs={6}>
                            {authMechanism == "OTP" ? (
                              <Link
                                className="text-secondary"
                                onClick={handleResend}
                              >
                                Resend code
                              </Link>
                            ) : null}
                          </Col>
                          <Col xs={6} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled={loading || !validation.values.authcode}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                  style={{ width: "1.1rem", height: "1.1rem" }}
                                />
                              ) : (
                                "Verify Code"
                              )}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )} */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordPage)
