import axios from "axios"
import Notify from "components/Common/notify";
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums";

const staging = "https://api-staging.shukreilahi.org/api/v1/"
const local = "http://25.16.188.189:5001/api/v1/"

export const axiosInstance = axios.create({
  baseURL: staging,
})

export const instanceAuth = axios.create({
  baseURL: staging,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add an interceptor to fetch the token dynamically for every request
instanceAuth.interceptors.request.use((config) => {
  const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor to handle 401 Unauthorized and redirect to login
instanceAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error code", error)
    if (error?.response?.status === 401 || error?.status === 401) {
      console.log("Unauthorization error")
      // localStorage.removeItem(SHUK_AUTH_PORTAL_TOKEN);
      Notify("error", "Session expired. Please login again.")
      const myTimeout = setTimeout(()=>{
        window.location.href = "/logout";
      }, 3000);
    }
    return Promise.reject(error);
  }
);
