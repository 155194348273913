import React, { useEffect, useState } from "react"
import { IoIosStats } from "react-icons/io"
import { FaShoppingBag, FaUserPlus } from "react-icons/fa"
import { MdCancel, MdDownloading } from "react-icons/md"
import { Row, Col } from "reactstrap"
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
import EmailSent from "./email-sent"
import MonthlyEarnings2 from "./montly-earnings2"
import Inbox from "./inbox"
import RecentActivity from "./recent-activity"
import WidgetUser from "./widget-user"
import YearlySales from "./yearly-sales"
import LatestTransactions from "./latest-transactions"
import LatestOrders from "./latest-orders"
import { useDispatch, useSelector } from "react-redux"
import { AllApplicationFetch, allApplications } from "redux/slice/applications/applicationSlice"

const Dashboard = () => {
  document.title = "Shukre Ilahi"
  const dispatch = useDispatch()

  // Fetch the applications data from Redux
  const { applicationsData } = useSelector(allApplications)

  // State to hold the status counts
  const [statusCounts, setStatusCounts] = useState({
    Total: 0,
    Submitted: 0,
    Process: 0,
    Completed: 0,
    Hold: 0,
    Reject: 0,
  })

  useEffect(() => {
    dispatch(AllApplicationFetch({}))
  }, [dispatch])

  useEffect(() => {
    const countApplicationStatus = () => {
      const counts = {
        Total: applicationsData ? applicationsData?.length : 0,
        Submitted: 0,
        Process: 0,
        Completed: 0,
        Hold: 0,
        Reject: 0,
      }
      applicationsData.forEach((application) => {
        const status = application.applicationStatus
        if (counts.hasOwnProperty(status)) {
          counts[status]++
        }
      })
      setStatusCounts(counts)
    }
    if (applicationsData && applicationsData.length) {
      countApplicationStatus()
    }
  }, [applicationsData])

  const reports = [
    {
      title: "New Application",
      icon: <FaShoppingBag />,
      total: statusCounts.Submitted, 
      average: applicationsData.length > 0 ? (statusCounts.Submitted / applicationsData?.length) * 100 : 0,
      badgecolor: "warning",
    },
    {
      title: "Process Application",
      icon: <IoIosStats />,
      total: statusCounts.Process, 
      average: applicationsData.length > 0 ? (statusCounts.Process / applicationsData?.length) * 100 : 0,
      badgecolor: "info",
    },
    {
      title: "Accepted Application",
      icon: <FaUserPlus />,
      total: statusCounts.Completed, 
      average: applicationsData.length > 0 ? (statusCounts.Completed / applicationsData?.length) * 100 : 0,
      badgecolor: "success",
    },
    {
      title: "Hold Application",
      icon: <MdDownloading />,
      total: statusCounts.Hold,
      average: applicationsData.length > 0 ? (statusCounts.Hold / applicationsData?.length) * 100 : 0,
      badgecolor: "secondary",
    },
    {
      title: "Rejected Application",
      icon: <MdCancel />,
      total: statusCounts.Reject,
      average: applicationsData.length > 0 ? (statusCounts.Reject / applicationsData?.length) * 100 : 0,
      badgecolor: "danger",
    },
  ]

  return (
    <React.Fragment>
      <Miniwidget reports={reports} />

      <Row>
        <Col xl="3">
          <MonthlyEarnings reports={reports} statusCounts={statusCounts} total={statusCounts.Total} />
        </Col>

        <Col xl="6">
          <EmailSent />
        </Col>

        <Col xl="3">
          <MonthlyEarnings2 />
        </Col>
      </Row>

      <Row>
        <Col xl="4" lg="6">
          <Inbox />
        </Col>
        <Col xl="4" lg="6">
          <RecentActivity />
        </Col>
        <Col xl="4">
          <WidgetUser />
          <YearlySales />
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <LatestTransactions />
        </Col>

        <Col xl="6">
          <LatestOrders applicationsData={applicationsData} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Dashboard
