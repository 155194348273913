import React, { useEffect, useRef } from "react";
import c3 from "c3";
import "c3/c3.css";

const DonutChart = ({ reports }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!reports || reports.length === 0) {
      return;
    }

    chartRef.current = c3.generate({
      bindto: "#donut-chart",
      data: {
        columns: reports.map(report => [report.title, report.total]),
        type: "donut",
      },
      donut: {
        width: 25,
        label: { show: true },
      },
      color: {
        pattern: ["#ffcc00", "#28bbe3", "#0ca670", "gray", "#bf3939"],
      },
      size: {
        height: 300,
      },
    });
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [reports]);

  return <div id="donut-chart"></div>;
};

export default DonutChart;
