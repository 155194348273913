import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance, instanceAuth } from "api/apiConfig"
import { Alert } from "reactstrap"
import {
  AUTH_USER_LOGIN_CONSTANT,
  AUTH_USER_REGISTER_CONSTANT,
  AUTH_USER_SENDCODE,
  AUTH_USER_VERIFY,
  REGISTER_AUTHENTICATOR,
  SHUK_AUTH_PORTAL_TOKEN,
} from "redux/enums"

const INITIAL_STATE = {
  firstname: "Arham",
  middlename: "Abeer",
  lastname: "Ahmed",
  email: "arham.abeer@braincrop.net",
  dob: "04-09-1999",
  contact: "090078601",
  role: "admin",
  loading: false,
  error: false,
  apiStatus: false, // 0: no api call, 1: api call success
  response: {},
  respFrom: "",
}

export const RegisterUser = createAsyncThunk(
  AUTH_USER_REGISTER_CONSTANT,
  async data => {
    try {
      const response = await axiosInstance.post("Auth/Register", data.data)
      console.log("response: ", response)

      if (response.status === 200) {
        const _res = {
          response: response.data,
        }
        return _res
      }
      throw response?.data
    } catch (error) {
      console.log("response: ", error?.message)

      throw error?.response?.data ? error?.response?.data : error?.message
    }
  },
)

export const LoginUser = createAsyncThunk(
  AUTH_USER_LOGIN_CONSTANT,
  async data => {
    try {
      const response = await axiosInstance.post("Auth/Login", data)
      if (response.status === 200) {
        // const token = response.data.token
        // localStorage.setItem(SHUK_AUTH_PORTAL_TOKEN, token)
        const _res = {
          response: response?.data,
        }
        return _res
      }
      throw response.data
    } catch (error) {
      console.log("error login", error?.response?.data)
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const sendAuthenticatorCode = createAsyncThunk(
  AUTH_USER_SENDCODE,
  async email => {
    try {
      const response = await axiosInstance.post(
        `Auth/SendEmailTwoFactorOTP/${email}`,
      )
      console.log("response from send code api: ", response)
      if (response.status === 200) {
        const _res = {
          response: response?.data,
          status: response?.status,
        }
        return _res
      }
      throw Error("Error sending authentication code.")
    } catch (error) {
      console.log("error login", error?.response?.data)
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const verifyAuthenticatorCode = createAsyncThunk(
  AUTH_USER_VERIFY,
  async data => {
    try {
      const response = await axiosInstance.post("Auth/LoginWith2FA", data)
      if (response.status === 200) {
        console.log("my token", response)
        const token = response.data?.token || response.data?.data?.token
        localStorage.setItem(SHUK_AUTH_PORTAL_TOKEN, token)
        const _res = {
          status: response?.status,
          response: response?.data,
        }
        return _res
      }
      throw Error("Wrong Code")
    } catch (error) {
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const registerAuthenticator = createAsyncThunk(
  REGISTER_AUTHENTICATOR,
  async data => {
    try {
      const response = await instanceAuth.post("Auth/Manage2FA", data)
      if (response.status === 200) {
        console.log("registerAuthenticator", response)
        const _res = {
          status: response?.status,
          response: response?.data,
        }
        return _res
      }
      throw Error("Wrong Code")
    } catch (error) {
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const UserSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "userSlice",
  reducers: {
    setApiResponseStutusToDefault: (state, action) => {
      return { ...state, apiStatus: false, error: false, response: {} }
    },
    setLogoutUser: (state, action) => {
      console.log("logout", action.payload)
      localStorage.removeItem(SHUK_AUTH_PORTAL_TOKEN)
      action.payload("/login")
      return { ...state, apiStatus: false, error: false, response: {} }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(RegisterUser.pending, (state, action) => {
        state.loading = true
      })
      .addCase(RegisterUser.fulfilled, (state, action) => {
        console.log("register fulfilled: ", action.payload)
        state.response = action.payload.response.message
        state.respFrom = "Register"
        state.apiStatus = true
        state.loading = false
      })
      .addCase(RegisterUser.rejected, (state, action) => {
        console.log("register rejected: ", action)
        state.response = action.error.message
        state.respFrom = "Register"
        state.apiStatus = true
        state.error = true
        state.loading = false
      })
    builder
      .addCase(LoginUser.pending, (state, action) => ({
        ...state,
        ...action.payload,
        loading: true,
      }))
      .addCase(LoginUser.fulfilled, (state, action) => {
        console.log("login fulfilled: ", action.payload)
        state.response = action.payload.response.message
        state.respFrom = "Login"
        state.apiStatus = true
        state.loading = false
      })
      .addCase(LoginUser.rejected, (state, action) => {
        console.log("login rejected: ", action)
        state.response = action.error.message
        state.respFrom = "Login"
        state.apiStatus = true
        state.error = true
        state.loading = false
      })
    builder
      .addCase(sendAuthenticatorCode.pending, (state, action) => {
        state.loading = true
      })
      .addCase(sendAuthenticatorCode.fulfilled, (state, action) => {
        state.response = action.payload.response?.message
          ? action.payload.response?.message
          : "Code Send"
        state.apiStatus = true
        state.loading = false
      })
      .addCase(sendAuthenticatorCode.rejected, (state, action) => {
        state.response = action.error.message
        state.error = true
        state.loading = false
      })
    builder
      .addCase(verifyAuthenticatorCode.pending, (state, action) => {
        state.loading = true
      })
      .addCase(verifyAuthenticatorCode.fulfilled, (state, action) => {
        console.log("code verified: ", action.payload)
        state.response = action.payload.response.message
        state.apiStatus = true
        state.loading = false
      })
      .addCase(verifyAuthenticatorCode.rejected, (state, action) => {
        console.log("error verifying code: ", action)
        state.response = action.error.message
        state.error = true
        state.loading = false
      })
    builder
      .addCase(registerAuthenticator.pending, (state, action) => {
        state.loading = true
      })
      .addCase(registerAuthenticator.fulfilled, (state, action) => {
        console.log("register authenticator: ", action.payload)
        state.response = action.payload.response.message
        state.apiStatus = true
        state.loading = false
      })
      .addCase(registerAuthenticator.rejected, (state, action) => {
        console.log("error register authenticator: ", action)
        state.response = action.error.message
        state.error = true
        state.loading = false
      })
  },
})

export const { setApiResponseStutusToDefault, setLogoutUser } =
  UserSlice.actions
export const userData = state => state.user
export default UserSlice.reducer
