import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Modal,
  Row,
  Table,
} from "reactstrap"
import {
  MdDelete,
  MdModeEdit,
  MdOutlineViewAgenda,
  MdFileDownload,
  MdOutlineAssignmentInd,
  MdQuestionMark,
  MdOutlinePrint,
  MdOutlineModeComment,
  MdOutlineExitToApp,
  MdEdit,
} from "react-icons/md"
import { useEffect, useState } from "react"
import {
  DataFilter,
  ItemsPerPage,
  SearchFilter,
} from "components/Common/filters"
import { useSelector, useDispatch } from "react-redux"
import PaginationComponent from "components/Common/pagination"
import Loader from "components/Common/loader"
import {
  allApplications,
  ApplicationDelete,
  AllApplicationFetch,
  ApplicationFetch,
  DiscripantApplication,
} from "redux/slice/applications/applicationSlice"
import ApplicationAssignStaff from "./application-assign-staff"
import { DateFormater } from "utilities/dateformater"
import { allStaffs, StaffFetch } from "redux/slice/staff/staffSlice"
import { SelectFieldForId, TextareaField } from "components/Common/input-fields"

export default function ApplicationsTable(props) {
  const dispatch = useDispatch()
  const { applicationsData, loading, totalPages } = useSelector(allApplications)
  const { staffMembersData } = useSelector(allStaffs)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedApplication, setSelectedApplication] = useState({})
  const [assignModalState, setAssignModalState] = useState(false)
  const [discrepantModalState, setDiscrepantModalState] = useState(false)
  const [discrepant, setDiscrepant] = useState({
    status: "No",
    comments: ""
  })
  const [selectedId, setSelectedId] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [filters, setFilters] = useState({
    status: "",
  })

  const paginate = pageNumber => {
    console.log("paginate call with page number: ", pageNumber)
    setCurrentPage(pageNumber)
    let data = {
      pageNumber: pageNumber,
      pageSize: itemsPerPage,
      ApplicationStatus: filters.status ? filters.status : null,
    }
    dispatch(AllApplicationFetch(data))
  }

  useEffect(()=>{
    dispatch(StaffFetch({}))
  }, [])

  useEffect(() => {
    let data = {
      pageNumber: 1,
      pageSize: itemsPerPage,
      ApplicationStatus: filters.status ? filters.status : null,
    }
    dispatch(AllApplicationFetch(data))
  }, [itemsPerPage, filters])

  const handleItemsPerPage = perPage => {
    console.log("perPage", perPage)
    setItemsPerPage(Number(perPage))
  }

  const handleView = received => {
    console.log("view", received)
    props.forUpdate(received);
    dispatch(ApplicationFetch(received?.id));
  }

  const handleDownload = received => {
    console.log("download");
    props.handleDownload(received?.id);
  }

  const handleAssignModal = () => {
    // console.log("receive_ids table", receive_ids)
    setAssignModalState(!assignModalState)
  }

  const handleQueries = received => {
    console.log("queries", received)
  }

  const handlePrint = received => {
    console.log("print")
    props.handlePrint(received?.id);
  }

  const handleComments = received => {
    console.log("comments", received)
  }

  const handleDiscrepant = () => {
    console.log("discrepant this", selectedId.id)
    console.log("discrepant", discrepant)
    let data = {
      stdId: selectedId.id,
      discrepantStatus: discrepant.status === "Yes" ? true : false
    }
    dispatch(DiscripantApplication(data))
    .then((res)=>{
      console.log(res)
      if(res.payload?.status){
        setDiscrepantModalState(false);
      }
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  function calculateAge(dateOfBirth) {
    var today = new Date();
    var birthDate = new Date(dateOfBirth);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody style={{ minHeight: "400px" }}>
              <CardTitle className="fs-2 dot-after">Applications</CardTitle>
              {/* Legend Section with Color Boxes */}
              {/* <div className="mb-3">
                <h6>Legend:</h6>
                <div className="d-flex align-items-center mb-2">
                  <span
                    className="badge bg-warning me-2"
                    style={{ width: "20px", height: "20px", display: "inline-block" }}
                  ></span>
                  <span>Not Eligible</span>
                </div>
                <div className="d-flex align-items-center">
                  <span
                    className="badge bg-success me-2"
                    style={{ width: "20px", height: "20px", display: "inline-block" }}
                  ></span>
                  <span>Eligible (age between 18 and 30)</span>
                </div>
              </div> */}
              <div className="row">
              <div className="col-sm-12 col-md-4 me-auto w-sm-100 float-start d-flex align-items-center">
                  {/* <div className="d-flex align-items-center mb-2"> */}
                    <span
                      className="badge me-2 text-bg-warning"
                      style={{
                        width: "15px",
                        height: "15px",
                        display: "inline-block",
                      }}
                    ></span>
                    <span className="me-3 text-nowrap">Age Not Eligible</span>
                  {/* </div> */}
                </div>
                <div
                  className="float-end col-sm-12 col-md-8 d-flex justify-content-end flex-row align-items-center"
                  // style={{ width: "100%" }}
                >
                  {/* <div className="me-auto w-sm-100">
                    <div className="d-flex align-items-center mb-2">
                      <span
                        className="badge me-2 text-bg-warning"
                        style={{ width: "15px", height: "15px", display: "inline-block" }}
                      ></span>
                      <span className="me-3 text-nowrap">Age Not Eligible</span>
                    </div>
                  </div> */}
                  <div className="me-3">
                    <SearchFilter
                      value={searchTerm}
                      label="Search Student"
                      onChange={e => {
                        setSearchTerm(e.target.value)
                      }}
                    />
                  </div>
                  <div className="me-3">
                    <DataFilter
                      options={[
                        { id: "", name: "All Application" },
                        { id: "Submitted", name: "Submitted Applications" },
                        // { id: "Pending", name: "Pending Application" },
                        { id: "Processing", name: "Processing Applications" },
                        { id: "Accepted", name: "Accepted Applications" },
                        { id: "Hold", name: "Hold Applications" },
                        { id: "Rejected", name: "Rejected Applications" },
                      ]}
                      value={filters.status}
                      label="Application Status"
                      onChange={e => {
                        setFilters(prev => ({
                          ...prev,
                          status: e.target.value,
                        }))
                      }}
                    />
                  </div>
                  <div>
                    <ItemsPerPage
                      value={itemsPerPage}
                      handleItemsPerPage={handleItemsPerPage}
                    />
                  </div>
                </div>

                
              </div>

              <div className="table-responsive">
                {loading ? (
                  <Loader />
                ) : (
                  <Table className="table mb-0 table-bordered">
                    <thead>
                      <tr>
                        <th className="text-nowrap">Application ID</th>
                        <th className="text-nowrap">Submit Date</th>
                        <th className="text-nowrap">Student Name</th>
                        <th className="text-nowrap">College Name</th>
                        <th className="text-nowrap">Status</th>
                        <th className="text-nowrap">Action</th>
                        <th className="text-nowrap">Assign Staff</th>
                        <th className="text-nowrap">Discrepant</th>
                        <th className="text-nowrap">Review Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {applicationsData.map((app, index) => {
                        // const age = calculateAge(date);
                        let rowClass = false
                        let age = null
                        if (app?.dateOfBirth) {
                          age = calculateAge(app.dateOfBirth)
                          if (age < 16 || age > 30) {
                            console.log("age", age)
                            // rowClass = "table-warning";
                            rowClass = true
                          }
                        }
                        return (
                          <tr key={app?.id} className={``}>
                            {/* <td className="text-nowrap">{staff.id}</td> ["96fe0338-75b4-4551-b5ae-08dcf4dbc176", "358e0852-18fc-40c7-b5af-08dcf4dbc176"] */}
                            <td className="text-nowrap">
                              {app?.applicationId}{" "}
                              {rowClass ? (
                                <span
                                  className="badge me-2 text-bg-warning float-end"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    display: "inline-block",
                                  }}
                                ></span>
                              ) : null}
                            </td>
                            <td className="text-nowrap">
                              {app?.submittedDate
                                ? DateFormater(app?.submittedDate)
                                : ""}
                            </td>
                            <td className="text-nowrap">
                              {app?.firstName + " "}{app?.middleName ? app?.middleName + " " : ""}{app?.lastName ? app?.lastName : ""}
                            </td>
                            <td className="text-nowrap">
                              {app?.instituteName}
                            </td>
                            <td className="text-nowrap">
                              {app?.applicationStatus}
                            </td>
                            <td className="d-flex">
                              <MdOutlineViewAgenda
                                style={{ cursor: "pointer" }}
                                className="me-2 text-success"
                                onClick={() => handleView(app)}
                                size={20}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View"
                              />
                              <MdFileDownload
                                style={{ cursor: "pointer" }}
                                className="me-2 text-info"
                                onClick={() => handleDownload(app)}
                                size={20}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Download"
                              />
                              <MdOutlineAssignmentInd
                                style={{ cursor: "pointer" }}
                                className="me-2 text-warning"
                                onClick={() => {
                                  setSelectedApplication(app)
                                  handleAssignModal()
                                }}
                                size={20}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Staff Assign"
                              />
                              <MdQuestionMark
                                style={{ cursor: "pointer" }}
                                className="me-2 text-danger"
                                onClick={() => handleQueries(app)}
                                size={20}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Queries"
                              />
                              <MdOutlinePrint
                                style={{ cursor: "pointer" }}
                                className="me-2 text-secondary"
                                onClick={() => handlePrint(app)}
                                size={20}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Print"
                              />
                            </td>
                            <td className="text-nowrap">
                              {app?.staffAssigned &&
                              app?.staffAssigned.length > 0
                                ? app.staffAssigned.map((staffId, index) => {
                                    const staffMember = staffMembersData.find(
                                      member => member.id === staffId,
                                    )
                                    return (
                                      <span key={staffId}>
                                        {staffMember
                                          ? staffMember.firstName
                                          : "Unknown"}
                                        {index < app.staffAssigned.length - 1 &&
                                          ", "}
                                        {/* {', '} */}
                                      </span>
                                    )
                                  })
                                : null}
                            </td>
                            <td className="text-nowrap d-flex justify-content-between">
                              <span className="mx-2">{app?.isDiscrepent ? "Yes" : "No"}</span>
                              <MdEdit 
                                style={{ cursor: "pointer" }}
                                className="text-danger"
                                onClick={() => {setDiscrepantModalState(true); setSelectedId(app); setDiscrepant((prev)=>({
                                  ...prev,
                                  status: app.isDiscrepent ? "Yes" : "No",
                                }))}}
                                size={18}
                              />
                            </td>
                            <td className="text-nowrap">
                              <MdOutlineModeComment
                                style={{ cursor: "pointer" }}
                                className="text-success"
                                onClick={() => handleComments(app)}
                                size={18}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-center justify-content-md-end justify-content-lg-end align-items-center flex-wrap">
                <div style={{ marginLeft: "4px" }}>
                  <PaginationComponent
                    totalPages={totalPages}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={assignModalState} centered>
        <ApplicationAssignStaff
          handleAssignModal={handleAssignModal}
          selectedApplication={selectedApplication}
        />
      </Modal>
      <Modal isOpen={discrepantModalState} onClosed={()=>setSelectedId("")} centered>
        <Card className="bg-white">
          <CardBody className="mt-2">
            <h5 style={{ fontWeight: "normal" }}>Are You Sure. You want to Discrepant <span className="fw-bold">{selectedId.firstName + " " + selectedId?.lastName}</span>?</h5>
              <SelectFieldForId
                className="col-sm-12 col-md-12 col-lg-12 my-2"
                label="Select Discrepant Status"
                compulsory={true}
                disabled={false}
                selectOptions={[{id: "No", name: "No"}, {id: "Yes", name: "Yes"}]}
                value={!discrepant.status ? "No" : discrepant?.status}
                placeholder="Type Currency"
                onChange={e => setDiscrepant((prev)=>({
                  ...prev,
                  status: e.target.value
                }))}
              />
              <div className={`col-sm-12 col-md-12 col-lg-12 my-2`}>
                <label>Comments</label>
                <textarea
                  className={`form-control text-black`}
                  rows={3}
                  disabled={true}
                  value={discrepant.comments}
                  placeholder="Enter Comments"
                  onChange={e => setDiscrepant((prev)=>({
                    ...prev,
                    comments: e.target.value,  
                  }))}
                  required
                />
              </div>
              {/* <TextareaField
                label="Comments"
                className="col-sm-12 col-md-12 col-lg-12 my-2"
                compulsory={true}
                disabled={true}
                rows="3"
                value={discrepant.comments}
                placeholder="Enter Comments"
                onChange={e => setDiscrepant((prev)=>({
                  ...prev,
                  comments: e.target.value,  
                }))}
              /> */}

          </CardBody>
          <CardFooter>
            <div className="mt-4 float-end">
              <button
                className="btn btn-danger me-2"
                onClick={() => handleDiscrepant()}
              >
                Ok
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setDiscrepantModalState(false);
                  setDiscrepant((prev)=>({
                    ...prev,
                    status: "",
                    comments: "",  
                  }))
                }}
              >
                Close
              </button>
            </div>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  )
}
