import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import { AssignStaff, UpdateAssignStaff } from "redux/slice/applications/applicationSlice";
import { allStaffs, StaffFetch } from "redux/slice/staff/staffSlice";

export default function ApplicationAssignStaff({
  handleAssignModal,
  selectedApplication,
}) {
  const dispatch = useDispatch();
  const { staffMembersData } = useSelector(allStaffs);
  const [staffData, setStaffData] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState(
    selectedApplication?.staffAssigned ? selectedApplication.staffAssigned : []
  );

  useEffect(() => {
    const transformedArray = staffMembersData.map(item => ({
      value: item?.id,
      label: item?.firstName + " " + item.lastName,
    }));
    setStaffData(transformedArray);
  }, [staffMembersData]);

  useEffect(() => {
    if (selectedApplication?.staffAssigned) {
      setSelectedMembers(selectedApplication.staffAssigned);
    }
  }, [selectedApplication]);

  const handleAssign = async () => {
    let data = {
      studentId: selectedApplication.id,
      staffIds: selectedMembers,
    };
    if(selectedApplication?.staffAssigned && selectedApplication?.staffAssigned.length > 0){
      console.log("update assign members", data);
      let res = await dispatch(UpdateAssignStaff(data));
      console.log("response of assign", res)
      if(res?.payload?.status === 200){
        handleAssignModal();
      }  
    }
    else{
      console.log("assign members", data);
      let res = await dispatch(AssignStaff(data));
      console.log("response of assign", res)
      if(res?.payload?.status === 200){
        handleAssignModal();
      }
    }
  };

  return (
    <Card className="bg-white">
      <CardBody className="mt-2">
        <CardTitle tag="h3" className="text-secondary-si fs-3">
          Assign Staff
        </CardTitle>
        <h5 className="mt-4">
          Application ID: {selectedApplication?.applicationId}
        </h5>
        <Row>
          <Col className="mt-4">
            <label>Select Staff Members</label>
            <Select
              options={staffData}
              value={
                selectedMembers.map(id => staffData.find(option => option.value === id)) || []
              }
              onChange={selectedOptions => {
                setSelectedMembers(
                  selectedOptions ? selectedOptions.map(option => option.value) : []
                );
              }}
              placeholder="Select Staff Members"
              isClearable
              className="rounded"
              isMulti={true}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <div className="mt-4 float-end">
          <button
            className="btn btn-primary me-2"
            onClick={() => handleAssign(selectedApplication)}
          >
            Submit
          </button>
          <button className="btn btn-danger" onClick={handleAssignModal}>
            Close
          </button>
        </div>
      </CardFooter>
    </Card>
  );
}
