import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "api/apiConfig"
import {
  SHUK_AUTH_PORTAL_TOKEN,
  SHUK_FP_AUTH_METHOD,
  SHUK_FP_EMAIL_VERIFICATION,
  SHUK_FP_OTP_VERIFY,
  SHUK_FP_RESET_PASSWORD,
} from "redux/enums"
import { error } from "toastr"

const INITIAL_STATE = {
  loading: false,
  error: false,
  apiStatus: false, // 0: no api call, 1: api call success
  response: "",
}

export const VerifyEmail = createAsyncThunk(
  SHUK_FP_EMAIL_VERIFICATION,
  async email => {
    try {
      const response = await axiosInstance.post("Auth/", email)
      if (response.status === 200) {
        const _res = {
          response: response.data,
          status: response.status,
        }
        return _res
      }
      throw Error("Email Verification Error.")
    } catch (error) {
      throw error?.response?.data ? error?.response?.data : error?.message
    }
  },
)

export const ForgotPasswordSendEmail = createAsyncThunk(
  SHUK_FP_AUTH_METHOD,
  async data => {
    try {
      console.log("email is:", data.email)
      const response = await axiosInstance.post(`Auth/SendResetPasswordLink/${data.email}`)
      if (response.status === 200) {
        const _res = {
          response: response?.data,
          status: response?.status,
        }
        return _res
      }
      throw response?.data?.message
    } catch (error) {
      console.log(error?.response?.data)
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const Forgot_Pass_Otp_Verify = createAsyncThunk(
  SHUK_FP_OTP_VERIFY,
  async (data) => {
    try {
      const response = await axiosInstance.post(
        `Auth/`,
      )
      if (response.status === 200) {
        const _res = {
          response: response?.data,
          status: response?.status,
        }
        return _res
      }
      throw Error("Error verifying otp code.")
    } catch (error) {
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const PasswordReset = createAsyncThunk(
  SHUK_FP_RESET_PASSWORD,
  async (data) => {
    try {
      console.log(data)
      const response = await axiosInstance.post(`Auth/ResetPassword/${data.email}/${encodeURIComponent(data.token)}`, {
        password: data.password,
        confirmPassword: data.confirmPassword,
      })
      if (response.status === 200) {
        const _res = {
          status: response?.status,
          response: response?.data,
        }
        return _res
      }
      throw Error("Unable to Reset Password")
    } catch (error) {
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const ForgotPasswordSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "ForgotPasswordSlice",
  reducers: {
    resetForgotState: (state, action) => {
      return { ...state, apiStatus: false, error: false, response: "" }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(VerifyEmail.pending, (state, action) => {
        state.loading = true
        state.error = false
      })
      .addCase(VerifyEmail.fulfilled, (state, action) => {
        state.response = action.payload.response.message
        state.apiStatus = true
        state.loading = false
      })
      .addCase(VerifyEmail.rejected, (state, action) => {
        console.log("register rejected: ", action)
        state.response = action.error.message
        state.apiStatus = true
        state.error = true
        state.loading = false
      })
    builder
      .addCase(ForgotPasswordSendEmail.pending, (state, action) => {
        state.loading = true
        state.error = false
      })
      .addCase(ForgotPasswordSendEmail.fulfilled, (state, action) => {
        state.response = action.payload.response.message
        state.apiStatus = true
        state.loading = false
      })
      .addCase(ForgotPasswordSendEmail.rejected, (state, action) => {
        console.log("error", action?.error?.message)
        state.response = action?.error?.message
        state.apiStatus = true
        state.error = true
        state.loading = false
      })
    builder
      .addCase(Forgot_Pass_Otp_Verify.pending, (state, action) => {
        state.loading = true
        state.error = false
      })
      .addCase(Forgot_Pass_Otp_Verify.fulfilled, (state, action) => {
        state.response = action.payload.response?.message
          ? action.payload.response?.message
          : "Send"
        state.apiStatus = true
        state.loading = false
      })
      .addCase(Forgot_Pass_Otp_Verify.rejected, (state, action) => {
        state.response = action.error.message
        state.error = true
        state.loading = false
      })
    builder
      .addCase(PasswordReset.pending, (state, action) => {
        state.loading = true
        state.error = false
      })
      .addCase(PasswordReset.fulfilled, (state, action) => {
        console.log("code verified: ", action.payload)
        state.response = action.payload.response.message
        state.apiStatus = true
        state.loading = false
      })
      .addCase(PasswordReset.rejected, (state, action) => {
        state.response = action.error.message
        state.error = true
        state.loading = false
      })
  },
})

export const { resetForgotState } = ForgotPasswordSlice.actions
export const ForgotPasswordBlock = state => state.ForgotPasswordBlock
export default ForgotPasswordSlice.reducer
