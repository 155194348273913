import React, { useEffect, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap"
import logoDark from "../../assets/images/Shukre_Ilahi_2.png"
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"
import { IoMdArrowRoundBack } from "react-icons/io"
import { MdOutlinePassword } from "react-icons/md"
import { PiLinkBold } from "react-icons/pi"
import { TiTick } from "react-icons/ti"
import { ForgotPasswordBlock, PasswordReset } from "redux/slice/user/forgotPasswordSlice"
import { useSearchParams } from "react-router-dom";
import Notify from "components/Common/notify"

const ResetPassword = props => {
  document.title = "Reset Password | Shukre Ilahi"
  const location = useLocation()
  const [searchParams] = useSearchParams();
  const emailinParam = searchParams.get("email");
  const tokeninParam = searchParams.get("token");
  // const { id, key } = location.state || {}
  const { error, loading, response } = useSelector(ForgotPasswordBlock)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [stage, setStage] = useState(1)
  const [errorMsg, setErrorMsg] = useState("")

  // if (id && key) {
  //   console.log(id, key)
  // }

  useEffect(()=>{
    console.log("email params", emailinParam)
    console.log("token params", tokeninParam)
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-+*/\[\]\(\)])[\S]{6,}$/,
          "Password must contain at least one letter, one number, and one special character",
        )
        .required("Please Enter Your Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Enter Your Confirm Password"),
    }),
    onSubmit: values => {
      console.log("Resetting password in progress....")
      let data = {
        password: values.password,
        confirmPassword: values.confirmPassword,
        email: emailinParam,
        token: tokeninParam,
      }
      dispatch(PasswordReset(data))
        .then(result => {
          if (result?.payload?.response?.status === 200) {
            Notify("success", "Password reset login to continue.")
            setTimeout(()=>{
              navigate("/login")
            }, 2000)
          } else {
            setErrorMsg("Error Resetting password.")
          }
        })
        .catch(err => {
          setErrorMsg("Error Resetting password.")
        })
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <img
                      src={logoDark}
                      alt=""
                      height="230"
                      className="auth-logo-dark"
                    />
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">
                      Reset Password
                    </h4>
                    {( error && response ) || errorMsg && (!validation?.errors?.password && !validation?.errors?.confirmPassword) && <Alert color={"danger"}>{response || errorMsg}</Alert>}
                    {/* {apiStatus && error && respFrom === "Login" ? (
                      <Alert color={"danger"}>{response}</Alert>
                    ) : apiStatus && !error && respFrom === "Login" ? (
                      <Alert color={"success"}>{response}</Alert>
                    ) : null} */}

                    {validation.touched.confirmPassword &&
                    (validation.errors.password ||
                      validation.errors.confirmPassword) ? (
                      <Alert color={"danger"}>
                        {validation.errors.password ||
                          validation.errors.confirmPassword}
                      </Alert>
                    ) : null}
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={validation.handleSubmit}
                    >
                      <div className="mb-3">
                        <Label htmlFor="password">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          required
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password}
                        />
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="confirm-password">
                          Confirm Password
                        </Label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          required
                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword}
                        />
                      </div>

                      <Row className="mb-3 mt-4">
                        <Col>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn btn-success w-md waves-effect waves-light"
                              type="submit"
                              disabled={
                                loading ||
                                !validation.values.password ||
                                !validation.values.confirmPassword
                              }
                            >
                              {loading ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                  style={{ width: "1.1rem", height: "1.1rem" }}
                                />
                              ) : (
                                "Reset"
                              )}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ResetPassword)
