import { DateFormater } from "./dateformater"

export const MapDataToApplicationState = backendData => {
  console.log(
    "backendData?.studentSignature?.signatureImageUrl",
    backendData?.studentSignature?.signatureImageUrl,
  )
  return {
    personal: {
      firstName: backendData?.firstName || "",
      middleName: backendData?.middleName || "",
      lastName: backendData?.lastName || "",
      dateOfBirth: backendData?.dateOfBirth
        ? DateFormater(backendData?.dateOfBirth)
        : "",
      email: backendData?.email ? backendData?.email : "",
      phoneNumber: backendData?.phoneNumber || "+92",
      fatherFirstName: "",
      fatherMiddleName: "",
      fatherLastName: "",
      motherFirstName: "",
      motherMiddleName: "",
      motherLastName: "",
      homeAddress: backendData?.homeAddress || "",
      alternateAddress: backendData?.alternateAddress || "",
      postalCode: backendData?.postalCode || "",
      alternatePhoneNumber: backendData?.alternatePhoneNumber || "",
      country: backendData?.country || "",
      state: backendData?.state || "",
      city: backendData?.city || "",
      fatherOccupation: "",
      motherOccupation: "",
      annualIncome: backendData?.household
        ? backendData?.household?.annualIncome
        : "",
      incomeCurrency: backendData?.household
        ? backendData?.household?.incomeCurrency
        : "",
      houseStatus: backendData?.household
        ? backendData?.household?.houseStatus || ""
        : "",
      totalHouseholdMembers: backendData?.household
        ? backendData?.household?.totalHouseholdMembers || ""
        : "",
      householdDetails:
        backendData?.household && backendData?.household?.householdDetails
          ? backendData?.household.householdDetails.map(detail => ({
              name: detail?.name || "",
              age: detail?.age || "",
              relationship: detail?.relationship || "",
              occupation: detail?.occupation || "",
              annualIncome: detail?.annualIncome ? detail?.annualIncome : 0,
            }))
          : [],
    },
    academics: {
      instituteName: backendData.instituteInfo
        ? backendData.instituteInfo.instituteName || ""
        : "",
      affliatedUniversity: backendData.instituteInfo
        ? backendData.instituteInfo.affiliatedUniversity || ""
        : "",
      instituteWebsite: backendData.instituteInfo
        ? backendData.instituteInfo.instituteWebsite || ""
        : "",
      instituteAddress: backendData.instituteInfo
        ? backendData.instituteInfo.instituteAddress || ""
        : "",
      city: backendData.city || "",
      pinCode: backendData.pinCode || "",
      country: backendData.country || "",
      programName: backendData.instituteInfo
        ? backendData.instituteInfo.programName || ""
        : "",
      programCurrentYear: backendData.instituteInfo
        ? backendData.instituteInfo.programCurrentYear || ""
        : "",
      programDuration: backendData.instituteInfo
        ? backendData.instituteInfo.programDuration || ""
        : "",
      programStartDate: backendData?.instituteInfo?.programStartDate
        ? DateFormater(backendData.instituteInfo.programStartDate)
        : "",
      instituteAdditionalInfo: backendData.instituteInfo
        ? backendData.instituteInfo.instituteAdditionalInfo || ""
        : "",
    },
    educationExpenses: {
      currency: backendData.studentExpense
        ? backendData.studentExpense?.currency || ""
        : "",
      expenseList:
        backendData?.studentExpense && backendData?.studentExpense?.expenseDetails
          ? backendData?.studentExpense?.expenseDetails.map(expense => {
              const yearDetails = expense.expenseYearDetails || []
              const expenseYearData = {}

              yearDetails.forEach(detail => {
                const yearKey = detail.yearName.replace(/\s+/g, "") 
                expenseYearData[yearKey] = detail.total || 0 
              })

              return {
                expense: expense.expense || "",
                total: yearDetails.reduce(
                  (acc, curr) => acc + (curr.total || 0),
                  0,
                ), 
                ...expenseYearData, 
              }
            })
          : [],
      educationExpense: "",
      otherExpense: "",
      aboutShukreIlahi: "",
    },
    documents: {
      nicImage: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.idCardImageUrl]
        : [],
      passportSizePicture: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.passportImageUrl]
        : [],
      addmissionLettter: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.admissionLetterImageUrl]
        : [],
      tutionFeeSchedule: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.tuitionFeeScheduleImageUrl]
        : [],
      marksheet: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.marksheetGradesImageUrl]
        : [],
      incomeVerification: backendData?.supportingDocuments
        ? [backendData.supportingDocuments.incomeVerificationImageUrl]
        : [],
      feeReceiptsImage: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.feeReceiptsImageUrl]
        : [],
      miscellaneousExpense: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.miscellaneousImageUrl]
        : [],
      otherDocuments: backendData?.supportingDocuments
        ? [backendData?.supportingDocuments?.otherDocuments]
        : [],
    },
    refernce: {
      refernceDetails: backendData?.referenceDetails
        ? [backendData?.referenceDetails]
        : [],
    },
    authorization: {
      answers: backendData?.answers.length > 0 ? backendData?.answers : [],
      applicationConsideration: backendData?.applicationConsideration || "",
      signature: backendData?.studentSignature?.signatureImageUrl
        ? [backendData?.studentSignature?.signatureImageUrl]
        : [],
      place: backendData?.place || "",
      submittedDate: backendData?.submittedDate ? DateFormater(backendData?.submittedDate) : "",
      ipAddress: backendData?.ipAddress ? backendData?.ipAddress : "",
    },
  }
}
